.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  object-fit: cover;
  height: auto;
  width: auto;
}

@media only screen and (max-width: 350px) {
  .logoImage {
    object-fit: cover;
    height: auto;
    width: 100px;
  }
}


@for $i from 1 through 1000 {
  .width-#{$i} {
    width: #{$i}px;
  }
}
