@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-hsl-view: hsl(217.2 91.2% 59.8%);
  --primary-to-Hsl: hsl(75 100% 40%);
  --primary-hex: #098aea;

  --secondary-hsl-view: hsl(357, 90%, 45%);
  --secondary-to-Hsl: hsl(0 84.2% 60.2%);
  --secondary-hex: #da0b14;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 75 100% 40%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 0 7% 15%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 206 93% 48%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 75 100% 40%;
    --secondary-foreground: 210 40% 98%;
    --muted: 215 50% 20%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 215 50% 20%;
    --accent-foreground: 210 40% 98%;
    --destructive: 357 90% 45%;
    --destructive-foreground: 210 40% 98%;
    --border: 215 50% 20%;
    --input: 215 50% 20%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  input {
    @apply bg-gradient-to-r from-primary/5;
  }
  select {
    @apply border border-gray-300 rounded-xl;
  }
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  font-size: 14px;
}

.TooltipContent {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.TooltipContent[data-side='top'] {
  animation-name: slideUp;
}
.TooltipContent[data-side='bottom'] {
  animation-name: slideDown;
}

/* Taille et couleur des bullets de pagination */
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background-color: #098aea !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: #99cc00 !important;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 12px;
  }
}
